
import { Component, Vue, } from 'vue-property-decorator'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { ControListInterface, } from '@/store/types/Analysis/ControlList'

import NavigationGapComponent from '@/components/NavigationGap/Index.vue'
import LongDescriptionHandlerComponent from '@/components/LongDescriptionHandler/Index.vue'

import Avatar from 'vue-avatar'

@Component({
  components: {
    NavigationGapComponent,
    Avatar,
    LongDescriptionHandlerComponent,
  },
})
export default class ControlDetail extends Vue {
  private loading = false;
  private gapControlList = this.$store.state.GapModule.GapState.controlList.gap_analysis_controls
  private historyControlList = this.$store.state.HistoryModule.HistoryState.historyNav
  private detailSelected = this.$store.state.GapModule.GapState.detailSelected
  private versionApp = this.$store.state.GapModule.GapState.version_categories
  private typeUser = this.$store.state.AuthModule.AuthState.role
  private userId = this.$route.params.id
  private newindex = 0
  private templateIndex = 0
  private detailId: number | undefined = 0
  private finalList = false
  private nameSelected = ''
  private gapData: any = {}
  private description = ''
  private question = ''
  private role = this.$store.state.AuthModule.AuthState.role
  private rute = 'detailControl'
  private description_lines = 0
  private description_button = false
  private question_button = false
  private evidences: any[] = []
  private contentJustification = ''
  private redirect = 'false'
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];

  mounted () {
    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      this.rute = 'startupDetailControl'
    } else {
      this.rute = 'detailControl'
    }

    this.$route.params.id_control
      ? (this.detailId = Number(this.$route.params.id_control))
      : (this.detailId = this.detailSelected.id_control)

    this.redirect = this.$route.query.redirect ? this.$route.query.redirect.toString() : 'false'

    this.GetGapAnalysisControl()
  }

  get getTypeNav () {
    if (this.$route.name === 'clients') {
      return 0
    } else {
      return 2
    }
  }

  private goBack () {
    this.$router.go(-1)
  }

  private goToEvidence (value: any) {
    this.$router.push({ 
      name: value.is_evidence ? 'maintenanceDetail' : 'activityDetailClient',
      params: { activity_id: value.id, task_id: value.id,},
    })
  }

  private goToDataRoom () {
    if (this.typeUser === 'implementer' || this.typeUser === 'customer_success') {
      this.$router.push({ name: 'clientDataroom', params: { id: this.userId, }, })
    } else {
      this.$mixpanel.track('Ir a Dataroom desde Control')
      this.$router.push({ path: '/startup/dataroom', })
    }
  }
  private changeView (element: number) {
    if (this.$route.name === 'clientHistory' || this.$route.name === 'cardTecnical') {
      this.$emit('changeView', 1)
      return
    }
    element === 0 ? this.$router.go(-2) : this.$router.go(-1)
  }

  private nextControlBack (template: ControListInterface) {
    this.templateIndex = this.getListitems.findIndex(
      (p: ControListInterface) => p.id === template.id
    )

    this.newindex = this.templateIndex - 1
    this.detailId = this.getListitems[this.templateIndex - 1].id
    this.newindex === this.getListitems.length - 1
      ? (this.finalList = true)
      : (this.finalList = false)
    this.$store.commit('GapModule/SET_DETAIL_SELECTED', this.getListitems[this.templateIndex - 1])

    this.$router.push({
      name: this.rute,
      params: { id_control: this.detailId ? this.detailId.toString() : 'null', },
    })
    this.$mixpanel.track('Control Anterior')

    this.GetGapAnalysisControl()
  }

  private nextControlFront (template: ControListInterface) {
    this.templateIndex = this.getListitems.findIndex(
      (p: ControListInterface) => p.id === template.id
    )
    this.detailId = this.getListitems[this.templateIndex + 1].id
    this.newindex = this.templateIndex + 1
    this.newindex === this.getListitems.length - 1
      ? (this.finalList = true)
      : (this.finalList = false)
    this.$store.commit('GapModule/SET_DETAIL_SELECTED', this.getListitems[this.templateIndex + 1])
    this.$router.push({
      name: this.rute,
      params: { id_control: this.detailId ? this.detailId.toString() : 'null', },
    })
    this.$mixpanel.track('Control Siguiente')

    this.GetGapAnalysisControl()
  }

  private controlChange (element: ControListInterface) {
    if (element.id !== this.detailId) {
      this.$store.commit('GapModule/SET_DETAIL_SELECTED', element)
      this.newindex = this.getListitems.findIndex((p: ControListInterface) => p.id === element.id)

      this.newindex === this.getListitems.length - 1
        ? (this.finalList = true)
        : (this.finalList = false)

      this.detailId = element.id
      this.$router.push({
        name: this.rute,
        params: { id_control: this.detailId ? this.detailId.toString() : 'null', },
      })

      this.GetGapAnalysisControl()
    }
  }

  private changeGapVersion (element: number) {
    this.$store.commit('GapModule/SET_VERSION_CATEGORIES', element)
    this.GetGapAnalysisControl()
  }

  private GetGapAnalysisControl () {
    this.loading = true
    this.$store.dispatch('GapModule/GetGapAnalysisControl', this.detailId).then((data) => {
      this.evidences = data.data.gap_analysis.evidences
      this.nameSelected = `${data.data.gap_analysis.control.code} ${data.data.gap_analysis.control.name}`
      this.gapData = data.data.gap_analysis
      this.description = this.gapData.control.description
      this.question = this.gapData.control.question
      this.loading = false
      this.newindex = this.getListitems.findIndex(
        (p: ControListInterface) => p.id === this.detailId
      )

      data.data.gap_analysis.supports.forEach((element: any) => {
        if (element.support_type === 'justification') {
          this.contentJustification = element.description
        }
      })

      this.newindex === this.getListitems.length - 1
        ? (this.finalList = true)
        : (this.finalList = false)
    })
  }

  get getListitems () {
    if (this.versionApp === 0) {
      return this.gapControlList
    } else {
      return this.historyControlList
    }
  }
}
